import React from 'react';
import PropTypes from "prop-types";
import GetTranslation from "../../../helper/GetTranslation";
import GlobalTrans from "../../../helper/GlobalTrans";

const RendererVideo = (props) => {
    return (
        <video controls width={props.element.width} poster={props.element.thumb} src={GetTranslation({
            translations: props.element?.translations,
            defaultContent: props.element.src,
            translationField: 'src'
        })}>
            {GlobalTrans('browser_doesnt_support_video')}
        </video>
    );
};

RendererVideo.propTypes = {
    element: PropTypes.object.isRequired
}

export default RendererVideo;